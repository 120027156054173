.products_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    max-width: 1450px;
    margin: 100px auto 100px auto;
    gap: 50px;
    justify-items: center;
  }
  .products_container section {
    margin: 0 auto;
  }