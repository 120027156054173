.container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.container img {
  width: 100px !important;
}
.cart_product {
  display: flex;
  gap: 10px;
}
.btn_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.btn{
  padding: 5px 16px;
  font-weight: bold;
  cursor: pointer;
  background-color: #fff;
  border: none;  
}
.btn:hover svg{
  background-color: var(--primary-color);
}
.container div:not(.subtotal) {
  height: auto !important;
}
.cart_container {
  padding: 20px;
}
.cart_container h2,
.cart_container h3 {
  padding: 10px;
}
.cart_container hr {
  margin: 10px;
}
.subtotal {
  padding: 20px;
  min-width: 300px;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border: 1px solid rgb(193, 193, 193);
  background-color: rgb(242, 241, 241);
  border-radius: 5px;
}
.subtotal a {
  text-align: center;
  width: 100%;
  border: none;
  background-color: var(--primary-color);
  border-radius: 5px;
  padding: 5px 10px;
  text-decoration: none;
  color: black;
}
.subtotal a:hover {
  background: var(--primary-shade);
}
.subtotal > div,
.subtotal span {
  display: flex;
  gap: 20px;
}
@media screen and (max-width: 968px) {
  .container {
    flex-direction: column;
    order: 1;
  }
}
