
.footer {
    background-color: hsl(193, 94%, 50%);
    padding: 20px;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}
.footercontent p {
    margin: 5px 0;
    color: #333;
    font-size: 20px;
}

.footercontent a {
    color: #101315;
    text-decoration: none;
}

.footercontent a:hover {
    text-decoration: underline;
}