.payment__header {
  padding: 20px;
  text-align: center;
  font-size: 24px;
  background-color: rgb(234, 237, 237);
}
.payment {
  padding: 30px;
}
.flex {
  display: flex;
  gap: 20px;
  padding: 30px;
}
.flex h3 {
  min-width: 300px;
}

.flex img {
  width: 80px !important;
}
.flex div {
  height: auto !important;
}
.payment__card__container {
  max-width: 350px;
  width: 100%;
}
/* .payment__details {
  font-size: 20px;
} */
.payment__price {
  padding: 20px;
}
.payment__price > div {
  display: flex;
  font-size: 16px;
  gap: 10px;
}
.payment__details button {
  margin-top: 15px;
  width: 100%;
  border: none;
  background-color: var(--primary-color);
  border-radius: 5px;
  padding: 8px 10px;
  cursor: pointer;
}
.payment__details button:hover {
  background-color: var(--primary-shade);
}

.loading {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  color: rgb(52, 52, 52);
}

@media screen and (max-width: 768px) {
  .flex {
    flex-direction: column;
  }
}