.hero__img {
  position: relative;
}

.hero__img::before {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 300px;
  background-image: linear-gradient(
    to bottom,
    rgba(225, 225, 225, 0),
    rgba(255, 255, 255)
  );
}
