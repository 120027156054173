.card_container {
  width: 250px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  color: black;
  text-decoration: none;
  position: relative;
}
.card_container:hover .button {
  display: block;
}
.card_container img {
  padding: 10px;
  width: 100%;
  height: 200px;
  object-fit: contain;
}
.button {
  display: none;
  padding: 5px 10px;
  font-weight: 700;
  border: none;
  cursor: pointer;
  background-color: #fe69a9;
  width: 100%;
  margin: 10px 0;
  border-radius: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.button:hover {
  background-color: #52d549;
}
.rating {
  display: flex;
  align-items: center;
  padding: 10px 0px;
}
.products_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  max-width: 1450px;
  margin: 100px auto 100px auto;
  gap: 50px;
  justify-content: center;
}
.products_container section {
  margin: 0 auto;
}
.product_flexed {
  box-shadow: none;
  height: auto;
  display: flex;
  gap: 50px;
  width: 100%;
  height: 50vh;
}
.product_flexed h3 {
  padding: 20px, 0px;
}
.product_flexed .button {
  display: block;
  position: static;

  width: 150px;
  margin-right: 40px;
}
@media screen and (max-width: 768px) {
  .product_flexed {
    display: flex;
    flex-direction: column;
  }
}
